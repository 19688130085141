export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: ':زانکۆ ناس',
    headline: 'یان ناسینی زانکۆ ',
    description:
      'دڵخۆش دەبین بە ناساندنی زانکۆ بە خوێندکاران و یارمەتیدانیان بۆ  دۆزینەوەی بەشێکی گونجاو ',
    buttonLabel: 'دەستپێبکە ',
    imgStart: 'row',
    img: 'images/svg-1.svg',
    alt: 'Credit Card'
  };
  
  export const w = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '  ',
    headline: '  ',
    description:
      ' ',
    buttonLabel: '  ',
    imgStart: 'row-reverse',
    img: 'images/img-3.svg',
    alt: 'wezhay'
  };
  export const homeObj3 = {
    lightBg: false,
    lightText:1,
    lightTextDesc: 1 ,
    topLine:'',
    headline2: '....خوێندکارم لە بەشی',
     
    description:
     '',
      buttonLabel: 'وێژەیی',
    buttonLabe2: 'زانستی',
    imgStart: '',
    img: '',
    alt: 'choose'
  };
  export const homeObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'ڕێبەر',
    headline: '',
    description:' ئەم ڕێبەرە بۆ بەرچاو ڕونی تۆ ئامادە  کراوەتکایە بەووردی بیخوێنەرەوە تاکو لە کاتی پێشکەش کردن نەکەویتە هەڵەوە',
       
    buttonLabel: 'داگرتن',
    imgStart: 'start',
    img: 'images/svg-3.svg',
    alt: 'zanko'
  };
  export const homeObjThre = {
    lightBg: true,
    lightText: true,
    lightTextDesc:  true,
    topLine: 'ڕێبەر',
    headline: '',
    description:' ئەم ڕێبەرە بۆ بەرچاو ڕونی تۆ ئامادە  کراوەتکایە بەووردی بیخوێنەرەوە تاکو لە کاتی پێشکەش کردن نەکەویتە هەڵەوە',
       
    buttonLabel: 'داگرتن',
    imgStart: '',
    img: 'images/svg-3.svg',
    alt: 'zanko'
  };