import React, { useState } from "react";

import "react-css-dropdown/dist/index.css";
import axios from "axios";
import "./Form.css";
import { makeStyles } from "@material-ui/core/styles";
import Res from "./Res";
import Grid from "@material-ui/core/Grid";
const Formin = ({ submitForm }) => {
  const [shwen, setshwen] = useState(0);
  const [kurd, setkurd] = useState(0);
  const [arab, setarab] = useState(0);
  const [eng, seteng] = useState(0);
  const [bir, setbir] = useState(0);
  const [fyz, setfyz] = useState(0);
  const [kim, setkim] = useState(0);
  const [zynda, setzynda] = useState(0);

  /////////////////////////////////temp lang ?catagory:ARcatagory
  var errzynda = false;
  var errkurd = false;
  var errfyz = false;
  var errarab = false;
  var errkim = false;
  var erreng = false;
  var errbir = false;
  const [res, setres] = useState(false);
  const [ezynda, setezynda] = useState(false);
  const [ekurd, setekurd] = useState(false);
  const [efyz, setefyz] = useState(false);
  const [earab, setearab] = useState(false);
  const [ekim, setekim] = useState(false);
  const [eeng, seteeng] = useState(false);
  const [ebir, setebir] = useState(false);
  const [data, setdata] = useState([]);
  const ok = async () => {
    errzynda = false;
    errkurd = false;
    errfyz = false;
    errarab = false;
    errkim = false;
    erreng = false;
    errbir = false;
    //
    setezynda(false);
    setekurd(false);
    setefyz(false);
    setearab(false);
    setekim(false);
    seteeng(false);
    setebir(false);
    let v = await valid();
    if (v) {
      const newstudent = {
        shwen: shwen.toString(),
        zynda: "0",

        kurd: kurd,

        kim: "0",
        arab: arab,

        fyz: "0",
        eng: eng,
        bir: bir,
        zynda: zynda,
        fyz: fyz,
        kim: kim,
      };

      let response = await axios.post("/mark", newstudent);
      setdata(response);

      setres(true);
    } else {
      console.log("not valid");
    }
  };

  const valid = async () => {
    if (!zynda) {
      errzynda = true;
      setezynda(true);
    } else if (zynda < 50) {
      errzynda = true;
      setezynda(true);
    } else if (zynda > 100) {
      errzynda = true;
      setezynda(true);
    }

    if (!fyz) {
      errfyz = true;
      setefyz(true);
    } else if (fyz < 50) {
      errfyz = true;
      setefyz(true);
    } else if (fyz > 100) {
      errfyz = true;
      setefyz(true);
    }

    if (!kim) {
      errkim = true;
      setekim(true);
    } else if (kim < 50) {
      errkim = true;
      setekim(true);
    } else if (kim > 100) {
      errkim = true;
      setekim(true);
    }

    if (!kurd) {
      errkurd = true;
      setekurd(true);
    } else if (kurd < 50) {
      errkurd = true;
      setekurd(true);
    } else if (kurd > 100) {
      errkurd = true;
      setekurd(true);
    }

    if (!arab) {
      errarab = true;
      setearab(true);
    } else if (arab < 50) {
      errarab = true;
      setearab(true);
    } else if (arab > 100) {
      errarab = true;
      setearab(true);
    }

    if (!eng) {
      erreng = true;
      seteeng(true);
    } else if (eng < 50) {
      erreng = true;
      seteeng(true);
    } else if (eng > 100) {
      erreng = true;
      seteeng(true);
    }

    if (!bir) {
      errbir = true;
      setebir(true);
    } else if (bir < 50) {
      errbir = true;
      setebir(true);
    } else if (bir > 100) {
      errbir = true;
      setebir(true);
    }

    if (
      errbir === false &&
      erreng === false &&
      errarab === false &&
      errkim === false &&
      errfyz === false &&
      errzynda === false &&
      errkurd === false
    ) {
      return true;
    } else {
      return false;
    }
  };
  const useStyles = makeStyles((theme) => ({
    root: {},
    grid: {
      alignContent: "center",
    },
  }));
  const classes = useStyles();

  return (
    <div className="form-content-right">
      {!res ? (
        <div>
          <div style={{ zIndex: 33 }}>
            <h1 style={{ height: "10vh", fontSize: "1.5rem" }} className="h1">
              :سەرەتا ئەم فۆرمە پربکەرەوە
            </h1>
          </div>
          <div className={classes.root}>
            <Grid container spacing={3} justify="center">
              <Grid item xs={12} sm={6}>
                <div className="form-inputs">
                  <label className="form-label">نمرەی زیندەزانی</label>
                  <input
                    max="100"
                    min="0"
                    className={
                      !ezynda === false ? "form-input-red" : "form-input "
                    }
                    type="number"
                    name="zynda"
                    placeholder="نمرەی زیندەزانی"
                    onChange={(e) => setzynda(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-inputs">
                  <label className="form-label">نمرەی کوردی</label>
                  <input
                    max="100"
                    min="0"
                    required
                    className={
                      !ekurd === false ? "form-input-red" : "form-input "
                    }
                    type="number"
                    name="kurd"
                    placeholder="نمرەی کوردی"
                    onChange={(e) => setkurd(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-inputs">
                  <label className="form-label">نمرەی فیزیا</label>
                  <input
                    max="100"
                    min="0"
                    className={
                      !efyz === false ? "form-input-red" : "form-input "
                    }
                    type="number"
                    name="fyz"
                    placeholder="نمرەی فیزیا"
                    onChange={(e) => setfyz(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-inputs ">
                  <label className="form-label">نمرەی عەرەبی و ئاین</label>
                  <input
                    max="100"
                    min="0"
                    className={
                      !earab === false ? "form-input-red" : "form-input "
                    }
                    type="number"
                    name="arab"
                    placeholder="نمرەی عەرەبی و ئاین"
                    onChange={(e) => setarab(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-inputs">
                  <label className="form-label">نمرەی کیمیا</label>
                  <input
                    max="100"
                    min="0"
                    className={
                      !ekim === false ? "form-input-red" : "form-input "
                    }
                    type="number"
                    name="kim"
                    placeholder="نمرەی کیمیا"
                    onChange={(e) => setkim(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="form-inputs">
                  <label className="form-label">نمرەی ئینگلیزی</label>
                  <input
                    max="100"
                    min="0"
                    className={
                      !eeng === false ? "form-input-red" : "form-input "
                    }
                    type="number"
                    name="eng"
                    placeholder="نمرەی ئینگلیزی"
                    onChange={(e) => seteng(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-inputs">
                  <label className="form-label">نمرەی بیرکاری</label>
                  <input
                    max="100"
                    min="0"
                    className={
                      !ebir === false ? "form-input-red" : "form-input "
                    }
                    type="number"
                    name="bir"
                    placeholder="نمرەی بیرکاری"
                    onChange={(e) => setbir(e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="form-inputs  se">
                  <label className="form-label">شوێنی خوێندن</label>
                  <select
                    className="form-input"
                    name="shwen"
                    onChange={(e) => setshwen(e.target.value)}
                    dir="rtl"
                  >
                    <option value="0">هەموو</option>
                    <option value="1">هەولێر</option>
                    <option value="2"> سلێمانی و هەڵەبجە و گەرمیان</option>
                    <option value="3">دهۆک</option>
                  </select>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="btns">
            <a
              style={{
                backgroundColor: "red",
                textDecoration: "none",
                width: "30vw",
                height: 50,
                borderRadius: 2,
                paddingTop: 15,
                margin: 20, borderRadius:" 5px"
              }}
              href="./choose"
              rel="noreferrer"
            >
             
              <div
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontSize: "1em",
                  textDecoration: "none",
                }}
              >
                گەڕانەوە
              </div>{" "}
            </a>
            <button
              className="form-input-btn"
              onClick={() => {
                ok();
              }}
            >
              تۆمارکردن
            </button>
            <div
              style={{
                backgroundColor: "transparent",
                padding: 20,
                width: 120,
                height: 40,

                margin: "auto",
              }}
            ></div>
          </div>
        </div>
      ) : (
        <div>
          <Res data={data} />
        </div>
      )}
    </div>
  );
};

export default Formin;
