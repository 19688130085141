import React from "react";
import "./rebar.css";
import { Button } from "./Button";

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  return (
    <div
      className={lightBg ? "home__hero-section2" : "home__hero-section2 darkBg"}
    >
      <div className="container  ">
        <div
          className="row home__hero-row"
          style={{
            display: "flex",
            flexDirection: imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className="col">
            <div className="home__hero-text-wrapper">
              <div className="top-line">{topLine}</div>
              <h1 className={lightText ? "heading " : "heading dark"}>
                {headline}
              </h1>
              <p className="home__hero-subtitleR ">{description}</p>

              <div className="dw">
                <a href="images/REBER-2020.pdf" download>
                  <Button buttonSize="btn--wide" buttonColor="blue">
                    {buttonLabel}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="col Reb">
            <div className="home__hero-img-wrapperR">
              <img
                src={"images/11.svg"}
                alt={alt}
                className="home__hero-imgREB"
              />
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <div className="dwT">
              <a href="images/REBER-2020.pdf" download>
                <Button buttonSize="btn--wide" buttonColor="blue">
                  {buttonLabel}
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default HeroSection;
