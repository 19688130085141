import "./w.css";
import Res from "./Res";
import { Button } from "./Button";

import Formin from "./Formin";
import "./Form.css";
function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  isSubmitted,
  submitForm,
  unsubmitForm,
}) {
  return (
    <div>
      <div className={!isSubmitted ? "form-container" : "done"}>
        {/* { !isSubmitted} */}
       <Formin submitForm={submitForm} />  
      </div>
      <div className={!isSubmitted ? "dis" : "aper"}>
        <Button buttonSize="btn--wide" buttonColor="red" onClick={unsubmitForm}>
          گەرانەوە
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
