import React, { useState } from "react";
import "./choose.css";
import W from "./W";
import Z from "./Z";
import { Button } from "./Button";

import { w } from "././pages/HomePage/Date";
export function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline2,
  description,
  buttonLabel,
  buttonLabe2,
  img,
  alt,
  imgStart,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  function submitForm(props) {
    setIsSubmitted(true);
  }
  function unsubmitForm(props) {
    setIsSubmitted(false);
  }
  const [showz, setShowz] = React.useState(false);
  const [showw, setShoww] = React.useState(false);

  return (
    <div>
      <p className={showw + showz ? "   dis" : "text"}>{headline2}</p>
      <div className="container3">
        <div
          className="row home__hero-row3"
          style={{
            display: "flex",
            flexDirection: imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className={showw + showz ? "   dis" : "col"}>
            
              <Button
                buttonSize="btn--wide"
                buttonColor={!showz ? "blue" : "red"}
                onClick={() => setShowz(!showz, setShoww(false))}
              >
                {buttonLabe2}
              </Button>
            
          </div>

          <div className={showw + showz ? "   dis" : "col"}>
           
              <Button
                buttonSize="btn--wide"
                buttonColor={!showw ? "blue" : "red"}
                onClick={() => setShoww(!showw, setShowz(false))}
              >
                {buttonLabel}
              </Button>
           
          </div>
        </div>
        {showw ? (
          <div className="c33">
            {/**     ================================================================================================ */}
            <W
              {...w}
              isSubmitted={isSubmitted}
              submitForm={submitForm}
              unsubmitForm={unsubmitForm}
            />
          </div>
        ) : null}

        {showz ? (
          <div className="c33">
            {/**     ================================================================================================ */}
            <Z
              isSubmitted={isSubmitted}
              submitForm={submitForm}
              unsubmitForm={unsubmitForm}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default HeroSection;
