import React from "react";
import ReactDOM from "react-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./index.css";
import "./fonts/Rabar/Rabar_022.ttf";

ReactDOM.render(
  <App />,

  document.getElementById("root")
);
