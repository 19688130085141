import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/pages/HomePage/Home";
import chose from "./components/pages/choose/chose";
import rebar from "./components/pages/rebar/rebar";
import TB from "./components/pages/Tb/Tb";
import darbara from "./components/pages/darbara/darbara";
import ReactGA from "react-ga";

import { useEffect } from "react";
import PG from "./components/PG";

function App() {
  useEffect(() => {
    console.log("runnnnnn");
    ReactGA.initialize("G-DTBBNYJQV4");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="font-face-gm">
      <Router>
        <Navbar />
        <switch>
          <Route path="/" exact component={Home} />
          <Route path="/choose" exact component={chose} />
          <Route path="/rebar" exact component={rebar} />
          <Route path="/TB" exact component={TB} />
          <Route path="/about" exact component={darbara} />
        </switch>
      </Router>
    </div>
  );
}

export default App;
