 
import "./w.css";
import Res from './Res';
import { Button } from "./Button";
 
 import Forminz from './Forminz'
 import './Form.css';
function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  isSubmitted,
  submitForm,unsubmitForm
}) {
   
  return (
    <div> 
    <div className= {!isSubmitted ?'form-container':'done'}>
 
    {!isSubmitted ? (
      <Forminz submitForm={submitForm} />
    ) : (
  <Res />
    )}</div>
      <span className={!isSubmitted? 'dis':'aper'}> 
      <Button 
                buttonSize="btn--wide"
                buttonColor= 'red' 
                onClick={unsubmitForm}
              >
             گەرانەوە
              </Button></span>
  </div>
  
  );
}

export default HeroSection;
