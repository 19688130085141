 import React from 'react'
 
 import Choose from "../../darbara";
 import {homeObjTwo } from "./Date";
 const darbara = () => {
     return (
         <div>
              
              <div
      className= "home__hero-section1 darkBg" 
    >  
            <Choose{...homeObjTwo}/>
            
            </div>


            
       
     )
         </div>
     )
 }
 
 export default darbara
 