import React from "react";
import "./HeroSection.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  return (
    <div
      className={lightBg ? "home__hero-section1" : "home__hero-section1 darkBg"}
    >
      <div className="container">
        <div className="row1 home__hero-row1">
          <div className="bnj">
          <Link to ='/choose' > 
        <Button     buttonSize="btn--wide" buttonColor="blue"  >
                {buttonLabel}
              </Button> </Link> </div>
          <div className="col1">
            <div className="home__hero-img-wrapper i" id="firstimage">
              <img src={'images/aaa.svg'} alt={alt} className="home__hero-img" />
            </div>
          </div>

          <div className="col1">
            <div className="home__hero-text-wrapper" id="firstimage">
              <div className="top-line1">{topLine}</div>
              <h1 className={lightText ? "heading" : "heading dark"}>
                {headline}
              </h1>
              <p
                className={
                  lightTextDesc
                    ? "home__hero-subtitle"
                    : "home__hero-subtitle dark"
                }
              >
                {description}
              </p>
<div className="disand"  > 
 
<Link to ='/choose' > 
        <Button     buttonSize="btn--wide" buttonColor="blue"  >
                {buttonLabel}
              </Button> </Link>
              </div>
            </div>
            
          </div>  
        </div>
      </div>{" "}
    </div>
  );
}

export default HeroSection;
