import React from "react";
import "./rebar.css";

import ReactPlayer from "react-player";
function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  return (
    <div
      className={lightBg ? "home__hero-section2" : "home__hero-section2 darkBg"}
    >
      <div className="container">
        <div
          className="row home__hero-row"
          style={{
            display: "flex",
            flexDirection: imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className="col">
            <div className="home__hero-text-wrapperTB ">
              <div className="top-line">تێبینی</div>
              <h1 className={lightText ? "heading" : "heading dark"}></h1>

              <ul className="home__hero-tTB  ">
                <li>
                  {" "}
                  ١- ڕیزبەندی بەشەکان بەپێی کەمترین کۆنمرەی ساڵانی پێشووە
                </li>
                <br />
                <li>
                  ٢- وەرگرتن لەبەشەکان بەپێی سیستەمی کریدتە جگەلە بەشە
                  پزیشکیەکان
                </li>
                <br />
                <li>
                  ٣- کەمترین نمرەیەی بەشەکانی هونەرە جوانەکان و وەرزشی و خوێندنی
                  ئیسلامی ٦٠ نمرایە و بەشێوەی راستەوخۆیە
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="home__hero-text-wrapper">
              <div className="top-line">فێرکاری</div>
              <h1 className={lightText ? "heading" : "heading dark"}></h1>

              <div className="home__hero-t">
                <ReactPlayer
                  controls="true"
                  width="100%"
                  url="https://youtu.be/5Q3canFV1pE"
                />
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}

export default HeroSection;
