 import React from 'react'
 import Rebar from "../../rebar";
 import { homeObjTwo } from "./Date";
 import PG from '../../PG';
 const rebar = () => {
     return (
         <div>
               <div
     className= "home__hero-section3 darkBg" 
   >  
 <Rebar {...homeObjTwo} />
 
   </div>
         </div>
     )
 }
 
 export default rebar
 