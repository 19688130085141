import React from "react";
import Choose from "../../choose";
import { homeObj3 } from "./Date";
import PG from "../../PG";
const chose = () => {
  return (
    <div>
      <div className="home__hero-section1 darkBg">
        <Choose {...homeObj3} />
      
      </div>
    </div>
  );
};

export default chose;
