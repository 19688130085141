import React from "react";
import "./rebar.css";
import { Button } from "./Button";
import { FaFacebookF } from "react-icons/fa";
import { IconContext } from "react-icons";
import { FaInstagram } from "react-icons/fa";
import { FaLink } from "react-icons/fa";

function darbara({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  return (
    <div
      className={lightBg ? "home__hero-section2" : "home__hero-section2 darkBg"}
    >
      <div className="container  ">
        <div
          className="row home__hero-row"
          style={{
            display: "flex",
            flexDirection: imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className="col">
            <div className="home__hero-text-wrapper">
              <div className="top-line"> زانکۆناس</div>
              <div className="nwsin">
                زانکۆ ناس پەرەیپێدراوە لەلایەن  ( پەیوەند بەهمەن  ) لە ساڵی ٢٠٢١.
                ئامانجی زانکۆ ناس خزمەت کردن و ناساندنی زانکۆیە بە خوێنکارانی
                خۆشەویست
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div
                  className="dw"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    href="https://www.facebook.com/zankonas"
                    className="cool-link"
                    style={{
                      backgroundColor: "#276afb",
                      color: "white",
                      textDecoration: "none",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingRight: 30,
                      paddingLeft: 30,
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconContext.Provider
                      value={{
                        color: "white",
                        style: { backgroundColor: "transparent", fontSize: 25 },
                        className: "global-class-name",
                      }}
                    >
                      <FaFacebookF />
                    </IconContext.Provider>
                  </a>
                </div>
                <div
                  className="dw"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <a
                    href="https://www.instagram.com/zanko_nas/"
                    className="cool-link"
                    style={{
                      background: "linear-gradient(to right, #405DE6, #E1306C)",
                      color: "white",
                      textDecoration: "none",
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingRight: 30,
                      paddingLeft: 30,
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconContext.Provider
                      value={{
                        color: "white",
                        style: { backgroundColor: "transparent", fontSize: 25 },
                        className: "global-class-name",
                      }}
                    >
                      <FaInstagram />
                    </IconContext.Provider>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col Reb">
            <div className="home__hero-img-wrapperR">
              <img
                src={"images/dev1.png"}
                alt="paiwand bahman"
                className="home__hero-imgREB"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default darbara;
