import React, { Component } from "react";
import Loader from "react-loader-spinner";

import "./K24KurdishLight-Light-normal";

import "jspdf-autotable";

import "./res.css";

import { withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";

export const Component2 = withRouter(({ history, location }) => {});

class Res extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      sas: [],
      z: false,
      orgtableData: [],
      loding: false,
      perPage: 5,
      currentPage: 0,
      empty: false,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  async componentDidMount() {
    //let result = await axios.get("http://localhost:3001");
    // this.setState({bash:result.data});

    this.getData();
  }

  getData() {
    var tdata = this.props.data.data;

    var slice = tdata.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    if (this.props.data.data.length === 0) {
      this.setState({
        empty: true,
      });
    }
    for (let index = 0; index < this.props.data.data.length; index++) {
      if (this.props.data.data[index].presetz === 300) {
        this.setState({
          z: true,
          loding: true,
          sas: this.props.data.data[index].sas,
        });

        break;
      }
    }

    this.setState({
      pageCount: Math.ceil(tdata.length / this.state.perPage),
      orgtableData: tdata,
      tableData: slice,

      loding: true,
    });
  }

  render() {
    return (
      <div>
        {this.state.loding ? (
          <div className="bod">
            {!this.state.empty ? (
              <div>
                {this.state.z ? (
                  <h1>
                    هەژمارکردنی بەرزترین کۆنمرە بەلابردن یاخود هەژمارکردنی کوردی
                    و عەرەبی:{this.state.sas.toFixed(1)}
                  </h1>
                ) : (
                  <h1>زانکۆ ناس</h1>
                )}

                <table className="table">
                  <thead>
                    <th className="rotate t1 ">کۆد</th>
                    <th className=" rotated-text ">کەمترترین کۆنمرە</th>
                    <th className=" rotate t3">نمرەیی تۆ</th>
                    <th className="rotate t5">ناوی بەش</th>
                  </thead>
                  <tbody>
                    {this.state.tableData.map((tdata, item) => (
                      <tr className="tr">
                        <td data-label=":کۆد" className="trdis">
                          {tdata.dis}
                        </td>
                        <td data-label=":کۆنمرەی وەرگیراو" className="trnum">
                          {tdata.num}
                        </td>
                        <td data-label=":نمرەی تۆ" className="trsas">
                          {tdata.sas.toFixed(1)}{" "}
                        </td>
                        <td data-label=" : بەشی " className="trname">
                          {tdata.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    previousClassName={"pri"}
                    nextClassName={"nex"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />

                  <div
                    className="peshawa"
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={"images/4.png"}
                      alt="paiwand bahman"
                      style={{ width: 70, opacity: 0.5 }}
                    />
                    <br />
                    <p>
                      Developed by &nbsp;
                      <b>
                        <a
                          style={{ textDecoration: "none", color: "white" }}
                          href="https://www.facebook.com/peshawaGroup"
                        >
                          Peshawa Group
                        </a>
                      </b>
                    </p>
                  </div>

                  <div className="back" style={{ paddingTop: 60 }}></div>
                </table>
                <div
                  style={{
                    marginTop: 15,
                    backgroundColor: "blue",
                    width: 0,
                  }}
                >
                  <a
                    style={{
                      backgroundColor: "transparent",
                      color: "white",

                      textDecoration: "none",
                    }}
                    href="./choose"
                    rel="noreferrer"
                  >
                    <div
                      style={{
                        width: 100,
                        height: 30,
                        borderRadius: 2,

                        backgroundColor: "red",
                      }}
                    >
                      گەڕانەوە
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              <div className="nya">
                <h1> ببورە بەم کۆنمرەیە هیچ بەشێک وەرتناگرێت </h1>
              </div>
            )}
          </div>
        ) : (
          <div className="loader">
            <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
          </div>
        )}
      </div>
    );
  }
}

export default Res;
