import React from "react";

function PG({}) {
  return (
    <div
      style={{
        height:"100px",
        backgroundPosition:"center",
        backgroundImage: "url('/images/ad.webp')",
     backgroundSize:"center bottom",
         display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
       <br />
      <p style={{ textDecoration: "none", color: "white" }}>
        Developed by &nbsp;
        <b>
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.facebook.com/peshawaGroup"
          >
            Peshawa Group
          </a>
        </b>
      </p>
    </div>
  );
}

export default PG;
