import React from "react";
import HeroSection from "../../HeroSection";
 
import PG from '../../PG';
import { homeObjOne } from "./Date";
 
 
function Home() {
  //<TB {...homeObjThre}/>
  //<Rebar {...homeObjTwo} />

 //  <Choose{...homeObj3}/>
  return (
    <div className="home__hero-section1 darkBg">
      
      <HeroSection {...homeObjOne} />
      
       
        
    </div>
  );
}

export default Home;

 


// {
//   "shwen":"0",
//   "zynda": "0",  
//   "kurd": "77",
//   "kim": "0",    
//   "arab": "77",
//   "fyz": "0",    
//   "eng":"99", 
//   "bir": "99", 
//   "jug": "99", 
//   "abu": "99", 
//   "mezh": "88" 
// }