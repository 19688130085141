import React, { useState  } from "react";
 
import { Link } from "react-router-dom";
import "./Navbar.css";
import { IoSchoolOutline } from "react-icons/io5";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
 
function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
 
    window.addEventListener('resize', showButton);
     







  return (
    <><IconContext.Provider value={{ color: '#fff' }}>
      <div className="navbar">
        <div className="navbar-container container"> 
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <IoSchoolOutline className="navbar-icon" />
          {/*<img className='navbar-icon' src={logo}alt='logo'/> */}
         زانکۆناس
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div >
        
        <ul className={click ? "nav-menu active" : "nav-menu"}>
        
                   <li className="nav-item">
                     <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                         سەرەتا
                     </Link>
                  </li>
                  <li className="nav-item">
                        <Link to="/rebar" className="nav-links" onClick={closeMobileMenu}>
                        رێبەر
                           </Link>
                   </li>
                  <li className="nav-item">
                          <Link to="/choose" className="nav-links" onClick={closeMobileMenu}>
                         ڕیزبەندی کردن
                          </Link>
                   </li>
                   <li className="nav-item">
                        <Link to="/TB" className="nav-links" onClick={closeMobileMenu}>
                        تێبینی
                           </Link>
                   </li>

                  { // <li className="nav-item">
                         // <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                       //  بەشەکان

                       //   </Link>
                  // </li>
                }
                   <li className="nav-item">
                          <Link to="/about" className="nav-links"onClick={closeMobileMenu}>
                          دەربارە
                          </Link>
                        
          </li> 
         
        
        </ul>
        
        </div>
      </div></IconContext.Provider>
    </>
  );
}

export default Navbar;
