import React from 'react'
import TB from "../../TB";
import { homeObjThre} from "./Date";
import PG from '../../PG';
const rebar = () => {
    return (
        <div>
              <div
    className= "home__hero-sectionTB darkBg" 
  >  
<TB{...homeObjThre}/>
 
  </div>
        </div>
    )
}

export default rebar
